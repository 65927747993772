import type { SVGProps } from 'react';
export const EyeSlashFill = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill={props.fill || `currentColor`}
      d="M4.826 7.876c-1.058.98-1.986 2.208-2.72 3.677a1 1 0 0 0 0 .894C4.264 16.764 8.096 19 12 19c1.145 0 2.284-.192 3.377-.574l-2.519-2.518q-.415.091-.858.092a4 4 0 0 1-3.908-4.858zM11.995 8l-2.65-2.65C10.215 5.119 11.106 5 12 5c3.903 0 7.736 2.236 9.894 6.553a1 1 0 0 1 0 .894 13 13 0 0 1-2.243 3.209L16 12.005V12a4 4 0 0 0-4-4zM2.707 3.707a1 1 0 1 1 1.414-1.414l16.97 16.97a1 1 0 0 1-1.413 1.415z"
    />
  </svg>
);
