import type { SVGProps } from 'react';
export const MessageLinesFill = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill={props.fill || `currentColor`}
      fillRule="evenodd"
      d="M4 4a2 2 0 0 0-2 2v11a2 2 0 0 0 2 2h4.586l2.707 2.707a1 1 0 0 0 1.414 0L15.414 19H20a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2zm3 4.5a1 1 0 0 0 0 2h10a1 1 0 1 0 0-2zm0 4a1 1 0 1 0 0 2h6a1 1 0 1 0 0-2z"
      clipRule="evenodd"
    />
  </svg>
);
