import type { SVGProps } from 'react';
export const XSmallCircleFill = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill={props.fill || `currentColor`}
      fillRule="evenodd"
      d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2M9.207 7.793a1 1 0 0 0-1.414 1.414L10.586 12l-2.793 2.793a1 1 0 1 0 1.414 1.414L12 13.414l2.793 2.793a1 1 0 0 0 1.414-1.414L13.414 12l2.793-2.793a1 1 0 0 0-1.414-1.414L12 10.586z"
      clipRule="evenodd"
    />
  </svg>
);
