import { SupportedLocale } from '@zep/consts/localize';
import { useTranslation } from 'next-i18next';

export const useLocale = () => {
  const {
    i18n: { resolvedLanguage },
  } = useTranslation();

  // middleware 에서 제어하기에 undefined 상황이 안나온다
  return resolvedLanguage?.toLowerCase() as SupportedLocale;
};
