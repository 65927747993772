import type { SVGProps } from 'react';
export const BoltFill = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill={props.fill || `currentColor`}
      d="M13.583 3a.583.583 0 0 0-1.055-.344l-8 11a.583.583 0 0 0 .471.927h5.417v6.416a.583.583 0 0 0 1.055.344l8-11A.583.583 0 0 0 19 9.416h-5.416z"
    />
  </svg>
);
