import type { SVGProps } from 'react';
export const HeartFill = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill={props.fill || `currentColor`}
      d="M12.38 5.559a5.402 5.402 0 1 1 8.216 7.007l-7.812 8.593a1.06 1.06 0 0 1-1.568 0l-7.811-8.593A5.402 5.402 0 1 1 11.62 5.56l.38.475z"
    />
  </svg>
);
