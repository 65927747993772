import { safeLocalStorage } from '@toss/storage';

const UTM_STORAGE_KEY = 'utm_data';
const UTM_TIMESTAMP_KEY = 'utm_timestamp';

export interface UTMParams {
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_term?: string;
  utm_content?: string;
}

export const getUTMParams = (): UTMParams => {
  const params = new URLSearchParams(window.location.search);
  return {
    utm_source: params.get('utm_source') || undefined,
    utm_medium: params.get('utm_medium') || undefined,
    utm_campaign: params.get('utm_campaign') || undefined,
    utm_term: params.get('utm_term') || undefined,
    utm_content: params.get('utm_content') || undefined,
  };
};

export const getStoredUTMParams = () => {
  const utmData = localStorage.getItem(UTM_STORAGE_KEY);
  const result = {
    utm_source: '',
    utm_medium: '',
    utm_campaign: '',
    utm_term: '',
    utm_content: '',
  };
  if (!utmData) return result;
  try {
    const parsedData = JSON.parse(utmData) as UTMParams;

    return {
      utm_source: parsedData.utm_source || '',
      utm_medium: parsedData.utm_medium || '',
      utm_campaign: parsedData.utm_campaign || '',
      utm_term: parsedData.utm_term || '',
      utm_content: parsedData.utm_content || '',
    };
  } catch (e) {
    return result;
  }
};

function mergeUTMParams(
  existingMap: UTMParams,
  newParams: UTMParams,
): UTMParams {
  return Object.entries(newParams).reduce(
    (acc, [key, value]) => {
      if (value) {
        // 기존 Map에 이미 해당 키가 있는 경우, 쉼표로 구분된 값을 머지합니다
        acc[key as keyof UTMParams] = acc[key as keyof UTMParams]
          ? Array.from(
              new Set([
                ...(acc[key as keyof UTMParams]?.split(',') || []),
                value,
              ]),
            ).join(',')
          : value;
      }
      return acc;
    },
    { ...existingMap },
  );
}

export const saveUTMParams = (utmParams: UTMParams): UTMParams => {
  const existingUTMData = getStoredUTMParams();

  // 병합된 Map
  const mergedUTMMap = mergeUTMParams(existingUTMData, utmParams);

  safeLocalStorage.set(UTM_STORAGE_KEY, JSON.stringify(mergedUTMMap));
  safeLocalStorage.set(UTM_TIMESTAMP_KEY, Date.now().toString());

  return mergedUTMMap;
};
