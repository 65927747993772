import type { SVGProps } from 'react';
export const SendFill = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill={props.fill || `currentColor`}
      d="M17.867 2.01a1 1 0 0 0-.422.159l-15 10a1 1 0 0 0 .326 1.805l7.398 1.74zM11.805 16.53l5.59 5.223a1 1 0 0 0 1.682-.73l-.003-18.029a1 1 0 0 0-.141-.512z"
    />
  </svg>
);
