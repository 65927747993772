/**
 *  pathName 기반으로 페이지 이동시, 현재 queryString 을 연결해줍니다.
 *  주로 utm_ 기반의 쿼리스트링을 연결 해줄때 사용하고 있습니다
 * */
export const combinePathAndQuery = (
  path: string,
  query: Record<string, string | string[] | undefined>,
  ignoredKeys?: string[],
) => {
  try {
    const baseURL = window.location.origin;
    const urlObj = new URL(path, baseURL);
    const searchParams = urlObj.searchParams;

    Object.entries(query).forEach(([key, value]) => {
      if (ignoredKeys?.includes(key)) return;

      const paramValue = Array.isArray(value) ? value[0] : value;
      if (paramValue) {
        searchParams.set(key, paramValue);
      }
    });

    return urlObj.toString();
  } catch (error) {
    console.error('Invalid URL:', error);
    return path; // 에러 발생 시 원래 경로 반환
  }
};
