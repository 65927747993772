import { useCallback } from 'react';
import { ZepQuizApiError } from '@zep/api';
import { combinePathAndQuery } from '@zep/utils';
import { isAxiosError } from 'axios';
import { useRouter } from 'next/router';

import { clearStorageUser } from '../lib/auth';

export const useApiError = () => {
  const router = useRouter();

  const goLoginPage = useCallback(async () => {
    console.warn('token Expired');
    clearStorageUser();
    if (router.isReady) {
      await router.push(combinePathAndQuery('/login', router.query));
    }
  }, [router]);

  return (error: Error) => {
    if (error instanceof ZepQuizApiError) {
      if (error.httpStatus === 401) {
        goLoginPage();
        return;
      }
    }

    if (isAxiosError(error) && error.response?.status === 401) {
      goLoginPage();
    }
  };
};
