import type { SVGProps } from 'react';
export const CoinStackFill = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill={props.fill || `currentColor`}
      d="M12 10c4.97 0 9-1.79 9-4s-4.03-4-9-4-9 1.79-9 4 4.03 4 9 4"
    />
    <path
      fill={props.fill || `currentColor`}
      d="M12 11c3 0 9-1.29 9-3.5V10c0 2.21-4.03 4-9 4s-9-1.79-9-4V7.5C3 9.71 9 11 12 11"
    />
    <path
      fill={props.fill || `currentColor`}
      d="M21 11.5c0 2.21-6 3.5-9 3.5s-9-1.29-9-3.5V14c0 2.21 4.03 4 9 4s9-1.79 9-4z"
    />
    <path
      fill={props.fill || `currentColor`}
      d="M12 19c3 0 9-1.29 9-3.5V18c0 2.21-4.03 4-9 4s-9-1.79-9-4v-2.5c0 2.21 6 3.5 9 3.5"
    />
  </svg>
);
