import type { SVGProps } from 'react';
export const YoutubeFill = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill={props.fill || `currentColor`}
      fillRule="evenodd"
      d="M21.156 5.898c.317.32.544.716.66 1.151.427 1.605.427 4.952.427 4.952s0 3.347-.427 4.952a2.57 2.57 0 0 1-1.807 1.818c-1.598.43-7.985.43-7.985.43s-6.387 0-7.985-.43a2.57 2.57 0 0 1-1.807-1.819c-.427-1.604-.427-4.951-.427-4.951s0-3.347.427-4.952A2.57 2.57 0 0 1 4.039 5.23c1.598-.43 7.985-.43 7.985-.43s6.387 0 7.985.43c.434.119.83.35 1.147.668m-5.88 6.103L9.934 8.96v6.079z"
      clipRule="evenodd"
    />
  </svg>
);
