import type { SVGProps } from 'react';
export const CartFill = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill={props.fill || `currentColor`}
      fillRule="evenodd"
      d="M2 4a1 1 0 0 1 1-1h2a1 1 0 0 1 .984.821L6.38 6H21a1 1 0 0 1 .936 1.351l-3 8A1 1 0 0 1 18 16H7a1 1 0 0 1-.984-.821L4.166 5H3a1 1 0 0 1-1-1"
      clipRule="evenodd"
    />
    <path
      fill={props.fill || `currentColor`}
      d="M10 19a2 2 0 1 1-4 0 2 2 0 0 1 4 0M19 19a2 2 0 1 1-4 0 2 2 0 0 1 4 0"
    />
  </svg>
);
