import type { SVGProps } from 'react';
export const TrashFill = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill={props.fill || `currentColor`}
      fillRule="evenodd"
      d="M9 2a2 2 0 0 0-2 2v2H3a1 1 0 0 0 0 2h1.069l.867 12.142A2 2 0 0 0 6.931 22H17.07a2 2 0 0 0 1.995-1.858L19.93 8H21a1 1 0 1 0 0-2h-4V4a2 2 0 0 0-2-2zm6 4H9V4h6zm-4 5a1 1 0 1 0-2 0v6a1 1 0 1 0 2 0zm4 0a1 1 0 1 0-2 0v6a1 1 0 1 0 2 0z"
      clipRule="evenodd"
    />
  </svg>
);
