import { ReactNode, useId, useState } from 'react';
import NiceModal from '@ebay/nice-modal-react';
import { NameEditModal } from '@zep/components/modals';
import { ProPlanTooltip } from '@zep/components/ProPlanTooltip';
import { LogoutIcon, PaperIcon, PenPaperIcon } from '@zep/icons';
import { trackGnbAnalytics } from '@zep/layout';
import { useUser } from '@zep/lib/auth';
import { usePlanInquiryLink } from '@zep/module/payment';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@zep/ui';
import { ICON_COLOR } from '@zep/ui/ui-tokens/color';
import { cn } from '@zep/utils';
import { format } from 'date-fns';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

export const MyMenuDropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { user, logOut } = useUser();
  const { t } = useTranslation();
  const router = useRouter();
  const moveInquireLink = usePlanInquiryLink('replace');

  const menus: {
    text: ReactNode;
    icon: ReactNode;
    onClick: () => void;
    className?: string;
  }[] = [
    {
      className:
        user?.planType === 'FREE' ? 'cursor-pointer' : 'cursor-default',
      text: <PlanStatus />,
      icon: <BoltFillGradientIcon />,
      onClick: () => {
        if (user?.planType === 'FREE') {
          moveInquireLink();
        }
      },
    },
    {
      text: t('header.quizList.button'),
      icon: <PaperIcon size={'xs'} />,
      onClick: () => {
        trackGnbAnalytics('mylist', 'Header');
        router.push('/me/quiz');
        setIsOpen(false);
      },
    },
    {
      text: user?.username || '',
      icon: <PenPaperIcon size={'xs'} />,
      onClick: () => {
        NiceModal.show(NameEditModal, {
          initialName: user?.username,
        });
      },
    },
    {
      className: 'text-[#ff5353] focus:text-[#ff5353] hover:text-[#ff5353]',
      text: t('header.logout'),
      icon: <LogoutIcon size={'xs'} fill={ICON_COLOR.destructive} />,
      onClick: () => {
        logOut({ redirectLoginPage: false });
        trackGnbAnalytics('logout', 'Header');
      },
    },
  ];

  return (
    <DropdownMenu open={isOpen} onOpenChange={setIsOpen} modal={false}>
      <DropdownMenuTrigger
        className="items-centers  hidden h-[40px] max-w-[140px] overflow-hidden whitespace-nowrap rounded-[8px] py-[10px] pl-xs pr-2xs text-[14px] focus:outline-none md:flex"
        tabIndex={-1}>
        <div className={'flex items-center justify-center gap-4xs'}>
          <ProPlanTooltip />
          <span className={'max-w-[78px] truncate'}>{user?.username}</span>
        </div>
        <img
          className="w-[20px]"
          src="/assets/icon_caret_down_gray.svg"
          alt="more"
        />
      </DropdownMenuTrigger>
      <DropdownMenuContent
        align="end"
        className="shadow-[0_4px_8px_2px_rgba(28, 27, 31, 0.12)] max-h-[200px] min-w-[148px] rounded-[8px] px-[8px] py-3xs text-[13px]">
        <DropdownMenuGroup>
          {menus.map(({ text, icon, onClick, className }, idx) => (
            <DropdownMenuItem
              key={idx}
              onClick={onClick}
              className={cn(
                'cursor-pointer p-xs text-body-sm font-medium',
                className,
              )}>
              <div className={'shrink'}>{icon}</div>
              <span className={cn('ml-2 w-full truncate')}>{text}</span>
            </DropdownMenuItem>
          ))}
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

const PlanStatus = () => {
  const { t } = useTranslation();
  const { user } = useUser();

  if (!user?.planDuration?.endDate) {
    return (
      <span className={'text-body-sm-bold text-strong'}>
        {t('common.header.eventInquiry', { planName: 'PRO PLAN' })}
      </span>
    );
  }

  return (
    <div className={'flex flex-col'}>
      <span className={'text-caption-md-semibold text-assistive'}>
        {t('common.header.eventEndDate', {
          endDate: format(user.planDuration.endDate, 'yyyy-MM-dd') || '',
        })}
      </span>
      <span className={'text-body-sm-bold text-strong'}>
        {t('common.header.eventApplying', { planName: 'PRO PLAN' })}
      </span>
    </div>
  );
};

const BoltFillGradientIcon = () => {
  const id = useId(); // gradient 적용을 위한 id

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none">
      <path
        d="M9.05545 1.99976C9.05545 1.83131 8.94701 1.68202 8.78682 1.62993C8.62664 1.57784 8.45112 1.6348 8.35205 1.77102L3.01872 9.10435C2.93269 9.22265 2.92031 9.37921 2.98668 9.50956C3.05306 9.6399 3.18696 9.72198 3.33323 9.72198H6.94434V13.9998C6.94434 14.1682 7.05278 14.3175 7.21297 14.3696C7.37315 14.4217 7.54866 14.3647 7.64774 14.2285L12.9811 6.89516C13.0671 6.77686 13.0795 6.6203 13.0131 6.48995C12.9467 6.35961 12.8128 6.27753 12.6666 6.27753H9.05545V1.99976Z"
        fill={`url(#${id})`}
      />
      <defs>
        <linearGradient
          id={id}
          x1="2.94434"
          y1="7.99975"
          x2="13.0555"
          y2="7.99975"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#8478FF" />
          <stop offset="1" stopColor="#3DA9FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};
