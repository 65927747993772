import type { SVGProps } from 'react';
export const LoudspeakerFill = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill={props.fill || `currentColor`}
      d="M18 3a1 1 0 0 0-1.496-.868L9.734 6H4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h6l6.504 3.868A1 1 0 0 0 18 19zM19 14.556c1.268-.397 2.2-1.714 2.2-3.278S20.269 8.397 19 8z"
    />
    <path
      fill={props.fill || `currentColor`}
      d="M4 17h6v4a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1z"
    />
  </svg>
);
