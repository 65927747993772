import type { SVGProps } from 'react';
export const Heart = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill={props.fill || `currentColor`}
      fillRule="evenodd"
      d="M12.38 5.514c2.012-2.461 5.762-2.664 8.038-.436a5.21 5.21 0 0 1 .177 7.297l-7.81 8.412c-.202.216-.486.34-.785.34a1.07 1.07 0 0 1-.784-.34l-7.811-8.412a5.21 5.21 0 0 1 .177-7.297c2.277-2.228 6.026-2.025 8.037.436L12 5.98zm6.54 1.031c-1.384-1.354-3.663-1.23-4.885.265l-1.208 1.478c-.2.247-.505.39-.827.39s-.626-.143-.827-.39L9.965 6.81C8.742 5.315 6.464 5.191 5.08 6.545a3.165 3.165 0 0 0-.108 4.434L12 18.547l7.027-7.568a3.165 3.165 0 0 0-.108-4.434"
      clipRule="evenodd"
    />
  </svg>
);
