import type { SVGProps } from 'react';
export const WindowRight = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill={props.fill || `currentColor`}
      fillRule="evenodd"
      d="M22 5a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2zm-9 0H4v14h9zm2 0h5v14h-5z"
      clipRule="evenodd"
    />
    <path
      fill={props.fill || `currentColor`}
      d="M18.5 10a1 1 0 1 1-2 0 1 1 0 0 1 2 0M17.5 8a1 1 0 1 0 0-2 1 1 0 0 0 0 2"
    />
  </svg>
);
