import { HTMLAttributeAnchorTarget, PropsWithChildren, ReactNode } from 'react';
import { NoSSR } from '@zep/components';
import { SupportedLocale } from '@zep/consts/localize';
import { HomeLogo } from '@zep/layout';
import { HeaderContainer } from '@zep/layout/Header/HeaderContainer';
import { MobileDrawer, TriggerContent } from '@zep/layout/Header/MobileDrawer';
import {
  AuthButton,
  CreateNewQuizButton,
} from '@zep/layout/Header/UserManagementButton';
import { useUser } from '@zep/lib/auth';
import { Button } from '@zep/ui';
import { zepAnalytics } from '@zep/utils/analytics';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';

import { EnterCodeButton, ExploreButton } from './PageNavigatorButton';

export const GNB_BUTTON_INFO: Record<
  'pricing' | 'public' | 'notice' | 'guide',
  {
    link: string;
    text: string;
    track: string;
    alias: string;
    icon?: string | ReactNode;
    hideLocales: SupportedLocale[];
    target: HTMLAttributeAnchorTarget;
  }
> = {
  pricing: {
    alias: '프라이싱',
    link: '/pricing',
    target: '_self',
    icon: '/assets/icon_magnifying_glass.svg',
    text: 'common.header.pricing',
    track: '',
    hideLocales: [],
  },
  public: {
    alias: '둘러보기',
    link: '/public',
    target: '_self',
    text: 'header.explore.button',
    track: '',
    hideLocales: [],
  },
  guide: {
    alias: '가이드',
    link: 'common.header.guideLink',
    target: '_blank',
    text: 'common.header.userGuide',
    track: '',
    hideLocales: [],
  },
  notice: {
    alias: '공지사항',
    link: 'common.header.noticeLink',
    target: '_blank',
    text: 'common.header.notice',
    track: '',
    hideLocales: ['ja'],
  },
};

export function GnbHeader() {
  return (
    <HeaderContainer>
      <div className="flex items-center gap-[24px]">
        <HomeLogo location={'header'} />
        <div className={'gap hidden gap-sm md:flex'}>
          <LeftButtons />
        </div>
      </div>
      <div className="flex items-center gap-2xs">
        <div className={'flex md:hidden'}>
          <ExploreButton.Icon />
        </div>
        <AuthButton />
        <div className={'hidden gap-2xs md:flex md:items-center'}>
          <EnterCodeButton type={'normal'} />
          <CreateNewQuizButton type={'normal'} />
        </div>

        <NoSSR fallback={<TriggerContent />}>
          <MobileDrawer />
        </NoSSR>
      </div>
    </HeaderContainer>
  );
}

const LeftButtons = () => {
  const {
    t,
    i18n: { resolvedLanguage = 'ko' },
  } = useTranslation();
  const { user } = useUser();

  return (
    <>
      {Object.values(GNB_BUTTON_INFO)
        .filter(
          l => !l.hideLocales.includes(resolvedLanguage as SupportedLocale),
        )
        .map(d => (
          <GnbLeftButton key={d.link}>
            <Link
              href={d.link.startsWith('common') ? t(d.link) : d.link}
              target={d.target}
              prefetch={false}
              onClick={() => {}}>
              {t(d.text)}
            </Link>
          </GnbLeftButton>
        ))}
      {user?.admin && (
        <GnbLeftButton key={'admin'}>
          <Link href={'/admin'} target={'_blank'} prefetch={false}>
            어드민
          </Link>
        </GnbLeftButton>
      )}
    </>
  );
};

export const GnbLeftButton = (props: PropsWithChildren) => (
  <Button size={'sm'} transParented intent={'neutral'} asChild>
    {props.children}
  </Button>
);

export const GnbHeaderShareClass = 'hidden md:flex';

export const trackGnbAnalytics = (
  to: string,
  location: string,
  userName?: string,
) => {
  zepAnalytics.track(`enter_${to}`, {
    location,
    ...(to === 'create' && { name: userName }),
  });
};
