import type { SVGProps } from 'react';
export const QuestionmarkSmallCircleColor = (
  props: SVGProps<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill="#E9EAF2"
      fillRule="evenodd"
      d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10M10.486 9.164c.144-.864.947-1.398 1.904-1.353.458.022.861.184 1.138.443.26.243.472.628.472 1.246 0 .709-.242 1.001-.492 1.168-.322.215-.838.332-1.508.332a1 1 0 0 0-1 1v1a1 1 0 1 0 2 0v-.073c.555-.087 1.12-.264 1.617-.595C15.492 11.75 16 10.792 16 9.5c0-1.132-.413-2.06-1.106-2.707-.677-.632-1.555-.939-2.409-.98-1.668-.079-3.615.887-3.971 3.023a1 1 0 1 0 1.972.328M10.5 16.5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0"
      clipRule="evenodd"
    />
    <path
      fill="#7A7B89"
      d="M12 14a1 1 0 0 1-1-1v-1a1 1 0 1 1 2 0v1a1 1 0 0 1-1 1M10.5 16.5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0"
    />
    <path
      fill="#7A7B89"
      d="M12.39 7.81c-.957-.045-1.76.49-1.903 1.354a1 1 0 0 1-1.973-.329c.356-2.136 2.303-3.101 3.971-3.022.855.04 1.733.347 2.409.98C15.587 7.44 16 8.366 16 9.498c0 1.292-.508 2.25-1.383 2.832-.803.536-1.787.668-2.617.668a1 1 0 1 1 0-2c.67 0 1.186-.117 1.508-.332.25-.166.492-.459.492-1.168 0-.617-.211-1.002-.472-1.245-.277-.26-.68-.421-1.138-.443"
    />
  </svg>
);
