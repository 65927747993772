import type { SVGProps } from 'react';
export const CoinStack = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill={props.fill || `currentColor`}
      d="M5 6c0 .026.01.17.292.42.28.248.744.518 1.402.765C8.004 7.675 9.88 8 12 8s3.997-.324 5.306-.815c.658-.247 1.121-.517 1.402-.766C18.99 6.17 19 6.026 19 6s-.01-.17-.292-.42c-.28-.248-.744-.518-1.402-.765C15.996 4.325 14.12 4 12 4s-3.997.324-5.306.815c-.658.247-1.121.517-1.402.766C5.01 5.83 5 5.974 5 6M3 6c0-.803.437-1.448.965-1.916.53-.469 1.238-.846 2.027-1.142C7.578 2.347 9.702 2 12 2s4.422.347 6.008.942c.79.296 1.498.673 2.027 1.142C20.562 4.552 21 5.197 21 6v12c0 .803-.437 1.448-.965 1.916-.53.469-1.238.846-2.027 1.142-1.586.595-3.71.942-6.008.942s-4.422-.348-6.008-.942c-.79-.296-1.498-.673-2.027-1.142C3.437 19.448 3 18.803 3 18zm2 4c0 .025.01.17.292.42.28.248.744.518 1.402.765C8.004 11.675 9.88 12 12 12s3.997-.324 5.306-.815c.658-.247 1.121-.517 1.402-.766.282-.25.292-.394.292-.419V8.616a9 9 0 0 1-.992.442C16.422 9.653 14.298 10 12 10s-4.422-.347-6.008-.942A9 9 0 0 1 5 8.616zm0 2.616V14c0 .025.01.17.292.42.28.248.744.518 1.402.765C8.004 15.675 9.88 16 12 16s3.997-.324 5.306-.815c.658-.247 1.121-.517 1.402-.766.282-.25.292-.394.292-.419v-1.384q-.467.245-.992.442c-1.586.595-3.71.942-6.008.942s-4.422-.348-6.008-.942A9 9 0 0 1 5 12.616m0 4V18c0 .026.01.17.292.42.28.248.744.518 1.402.765C8.004 19.675 9.88 20 12 20s3.997-.324 5.306-.815c.658-.247 1.121-.517 1.402-.765.282-.25.292-.395.292-.42v-1.384q-.467.245-.992.442c-1.586.595-3.71.942-6.008.942s-4.422-.348-6.008-.942A9 9 0 0 1 5 16.616"
    />
  </svg>
);
