import type { SVGProps } from 'react';
export const QuestionmarkSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill={props.fill || `currentColor`}
      d="M10.487 9.164c.144-.864.946-1.399 1.903-1.353.458.022.861.184 1.138.443.26.243.472.628.472 1.245 0 .71-.242 1.002-.492 1.168-.322.215-.837.332-1.508.332a1 1 0 0 0-1 1v1a1 1 0 1 0 2 0v-.073c.556-.087 1.121-.264 1.617-.594C15.492 11.748 16 10.79 16 9.499c0-1.132-.413-2.06-1.106-2.707-.676-.632-1.554-.938-2.409-.979-1.668-.08-3.615.886-3.971 3.022a1 1 0 0 0 1.973.329M10.5 16.5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0"
    />
  </svg>
);
