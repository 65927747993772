import type { SVGProps } from 'react';
export const Send = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill={props.fill || `currentColor`}
      fillRule="evenodd"
      d="M18.246 2.03A1 1 0 0 1 19 3v18a1 1 0 0 1-1.678.735l-6.5-6a1 1 0 0 1-.201-1.211l6.5-12a1 1 0 0 1 1.125-.493M12.75 14.794 17 18.716V6.946z"
      clipRule="evenodd"
    />
    <path
      fill={props.fill || `currentColor`}
      fillRule="evenodd"
      d="M18.674 2.261a1 1 0 0 1 .205 1.215l-6.5 12a1 1 0 0 1-1.108.497l-8.5-2a1 1 0 0 1-.326-1.805l15-10a1 1 0 0 1 1.229.093M5.472 12.554l5.513 1.298L15.2 6.068z"
      clipRule="evenodd"
    />
  </svg>
);
