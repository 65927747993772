import type { SVGProps } from 'react';
export const SparklingFill = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill={props.fill || `currentColor`}
      fillRule="evenodd"
      d="M9.103 4.5c.6 0 1.087.48 1.087 1.071a5.32 5.32 0 0 0 1.59 3.789 5.47 5.47 0 0 0 3.84 1.569c.6 0 1.087.48 1.087 1.071 0 .592-.486 1.071-1.086 1.071-1.44 0-2.822.565-3.84 1.57a5.32 5.32 0 0 0-1.591 3.788c0 .591-.487 1.071-1.087 1.071s-1.086-.48-1.086-1.071a5.32 5.32 0 0 0-1.59-3.788 5.47 5.47 0 0 0-3.84-1.57c-.6 0-1.087-.48-1.087-1.071 0-.592.486-1.071 1.086-1.071a5.47 5.47 0 0 0 3.84-1.57 5.32 5.32 0 0 0 1.591-3.788c0-.591.487-1.071 1.086-1.071M18.88 14.857c.285 0 .517.229.517.51 0 .677.272 1.326.757 1.804a2.6 2.6 0 0 0 1.829.747c.285 0 .517.229.517.51s-.232.51-.517.51c-.686 0-1.344.27-1.829.748a2.53 2.53 0 0 0-.757 1.804c0 .282-.232.51-.518.51a.514.514 0 0 1-.517-.51c0-.677-.272-1.326-.757-1.804a2.6 2.6 0 0 0-1.83-.747.514.514 0 0 1-.516-.51c0-.282.231-.51.517-.51.686 0 1.344-.27 1.829-.748a2.53 2.53 0 0 0 .757-1.804c0-.281.232-.51.517-.51M18.88 2c.285 0 .517.228.517.51 0 .677.272 1.326.757 1.804a2.6 2.6 0 0 0 1.829.747c.285 0 .517.229.517.51s-.232.51-.517.51c-.686 0-1.344.27-1.829.748a2.53 2.53 0 0 0-.757 1.804c0 .281-.232.51-.518.51a.514.514 0 0 1-.517-.51c0-.677-.272-1.326-.757-1.804a2.6 2.6 0 0 0-1.83-.747.514.514 0 0 1-.516-.51c0-.282.231-.51.517-.51.686 0 1.344-.27 1.829-.748a2.53 2.53 0 0 0 .757-1.804c0-.282.232-.51.517-.51"
      clipRule="evenodd"
    />
  </svg>
);
