import type { SVGProps } from 'react';
export const PersonFill = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill={props.fill || `currentColor`}
      d="M12 2a6 6 0 1 0 0 12 6 6 0 0 0 0-12M3 21a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1 5 5 0 0 0-5-5H8a5 5 0 0 0-5 5"
    />
  </svg>
);
