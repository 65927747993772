import type { SVGProps } from 'react';
export const CaretUpdown = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill={props.fill || `currentColor`}
      d="m17 10-5-6-5 6zM17 13l-5 6-5-6z"
    />
  </svg>
);
