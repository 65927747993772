import type { SVGProps } from 'react';
export const InternetconnectionSlash = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill={props.fill || `currentColor`}
      d="m9.951 13 1.549 1.549v2.95a1.5 1.5 0 1 1-3 0v-3A1.5 1.5 0 0 1 9.951 13M13 16.049l2.536 2.535A1.5 1.5 0 0 1 13 17.5zM16 12.004l-1.94-1.94q.21-.064.44-.065a1.5 1.5 0 0 1 1.5 1.5zM20.5 16.504l-3-3V8.5a1.5 1.5 0 0 1 3 0zM4 17.5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0M6.97 7.969a1 1 0 0 1 1.414-1.414l12.428 12.428a1 1 0 0 1-1.414 1.414z"
    />
  </svg>
);
