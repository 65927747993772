import type { SVGProps } from 'react';
export const CurrencyYenCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill={props.fill || `currentColor`}
      d="M12 10.89 13.475 7H16l-2.285 5.272h1.511V13.7h-2.029v.7h2.03v1.427h-2.03V18H10.73v-2.173H8.496V14.4h2.234V13.7H8.496v-1.428h1.774L8 7h2.525z"
    />
    <path
      fill={props.fill || `currentColor`}
      d="M12 4a8 8 0 1 0 0 16 8 8 0 0 0 0-16M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12"
    />
  </svg>
);
