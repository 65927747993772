import ReactGA from 'react-ga4';
import {
  EventProperties,
  getStoredUTMParams,
  zepEventTracker,
} from '@zep/utils/analytics/';

import { StatefulPQueue } from '../common';

const queue = new StatefulPQueue({ autoStart: false });

function setUserProperties(records: EventProperties) {
  zepEventTracker.setContext(records);
  return Promise.resolve();
}

const trackEvent = (...params: Parameters<ZepAnalyticsType['track']>) => {
  ReactGA.event(String(params[0]), params[1] || {});
};

export const zepAnalytics: ZepAnalyticsType = {
  track: (eventName, _eventProperties) => {
    const utmParams = getStoredUTMParams();
    const eventProperties = { ..._eventProperties, ...utmParams };

    if (!queue.isReady) {
      return queue.add(() => {
        zepEventTracker.track(eventName, eventProperties);
        return trackEvent(eventName, eventProperties);
      });
    } else {
      zepEventTracker.track(eventName, eventProperties);
      return trackEvent(eventName, eventProperties);
    }
  },

  setAmplitudeUserId: (userId: string) => {
    zepEventTracker.addContext({ IS_LOGIN: true });
    zepEventTracker.setUserId(userId);
  },

  resetAmplitudeUserId: () => {
    zepEventTracker.addContext({ IS_LOGIN: false });
    zepEventTracker.setUserId(undefined);
  },

  setDefaultUserProperties: (eventProperties: EventProperties) => {
    setUserProperties(eventProperties).then(() => {
      queue.setReady(true).start();
    });
  },
};

type ZepAnalyticsType = {
  track: (eventName: string, eventProperties?: EventProperties) => void;
  setAmplitudeUserId: (userId: string) => void;
  resetAmplitudeUserId: () => void;
  setDefaultUserProperties: (eventProperties: EventProperties) => void;
};
