import type { SVGProps } from 'react';
export const BulbColor = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill="#F4C620"
      fillRule="evenodd"
      d="M19 9A7 7 0 1 0 5 9c0 1.26.256 2.232.62 3.006.356.754.8 1.282 1.102 1.642l.012.015.176.208c.674.798 1.042 1.234 1.086 1.987A1.21 1.21 0 0 0 9.2 17h5.6c.68 0 1.17-.543 1.204-1.142.044-.753.412-1.189 1.086-1.987l.176-.208.012-.015c.302-.36.746-.888 1.102-1.642.364-.774.62-1.747.62-3.006"
      clipRule="evenodd"
    />
    <path
      fill="#18D0B4"
      d="M9.4 19.25a.9.9 0 1 1 0-1.8h5.2a.9.9 0 1 1 0 1.8zM8.5 20.6a.9.9 0 0 0 .9.9h5.2a.9.9 0 1 0 0-1.8H9.4a.9.9 0 0 0-.9.9"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M11 6a2 2 0 0 0-2 2 1 1 0 0 1-2 0 4 4 0 0 1 4-4 1 1 0 1 1 0 2"
      clipRule="evenodd"
    />
  </svg>
);
