import type { SVGProps } from 'react';
export const SpeakerLv2Slash = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill={props.fill || `currentColor`}
      d="M3.952 7.001A1 1 0 0 0 3 8v8a1 1 0 0 0 1 1h3.586l3.707 3.707A1 1 0 0 0 13 20v-3.95l-2-2v3.536l-2.293-2.293A1 1 0 0 0 8 15H5V9h.95zM10.705 6.71 9.29 5.295l2.003-2.002A1 1 0 0 1 13 4v5.005l-2-2v-.59zM17.796 13.801l-1.562-1.562A2 2 0 0 0 16.25 12c0-.794-.526-1.361-.957-1.793a1 1 0 0 1 1.414-1.414l.028.028c.424.423 1.515 1.511 1.515 3.179 0 .707-.196 1.31-.454 1.801"
    />
    <path
      fill={props.fill || `currentColor`}
      d="m20.392 16.397-1.43-1.43C19.527 14.192 20 13.208 20 12c0-2.044-1.36-3.445-2.207-4.293a1 1 0 0 1 1.414-1.414l.034.033C20.078 7.163 22 9.084 22 12c0 1.893-.81 3.367-1.608 4.397M2.707 3.707a1 1 0 1 1 1.414-1.414l16.97 16.97a1 1 0 1 1-1.414 1.415z"
    />
  </svg>
);
