import type { SVGProps } from 'react';
export const BellSlash = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill={props.fill || `currentColor`}
      fillRule="evenodd"
      d="M5.216 8.266A7 7 0 0 0 5 10v4.697l-1.832 2.748A1 1 0 0 0 4 19h4.535a3.5 3.5 0 0 0 6.93 0H16.5l-2-2H5.868l.964-1.445A1 1 0 0 0 7 15v-4.95zM12 20a1.5 1.5 0 0 1-1.415-1h2.83A1.5 1.5 0 0 1 12 20"
      clipRule="evenodd"
    />
    <path
      fill={props.fill || `currentColor`}
      d="M9.605 5.61 8.149 4.154a7 7 0 0 1 1.997-.906 2 2 0 0 1 3.708 0A7 7 0 0 1 19 10v5l-2-1.995V10a5 5 0 0 0-7.395-4.39M2.707 3.707a1 1 0 1 1 1.414-1.414l16.97 16.97a1 1 0 1 1-1.414 1.415z"
    />
  </svg>
);
