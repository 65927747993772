import type { SVGProps } from 'react';
export const Trophy = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill={props.fill || `currentColor`}
      fillRule="evenodd"
      d="M7 3a1 1 0 0 0-1 1v1H3a1 1 0 0 0-1 1c0 1.127.275 2.819 1.143 4.265.833 1.389 2.227 2.554 4.347 2.716a5.4 5.4 0 0 0 1.998 1.52c-.064 1.334-.386 2.189-.78 2.702C8.292 17.742 7.725 18 7 18a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1c-.726 0-1.293-.258-1.707-.797-.395-.513-.717-1.368-.781-2.702a5.4 5.4 0 0 0 1.998-1.52c2.12-.162 3.514-1.327 4.348-2.716C21.725 8.819 22 7.127 22 6a1 1 0 0 0-1-1h-3V4a1 1 0 0 0-1-1zm11 4v2c0 .418-.073.985-.253 1.605.61-.32 1.058-.807 1.395-1.37.42-.7.661-1.512.775-2.235zM4.083 7H6v2c0 .418.073.985.253 1.605-.61-.32-1.058-.807-1.396-1.37-.42-.7-.66-1.512-.774-2.235m8.456 7.98a7 7 0 0 1-1.078 0c-.119 1.421-.506 2.582-1.168 3.442A3.93 3.93 0 0 1 8 19.892V20h8v-.108a3.93 3.93 0 0 1-2.293-1.47c-.662-.86-1.05-2.02-1.168-3.442M8 9V5h8v4c0 .487-.2 1.533-.822 2.43C14.593 12.276 13.636 13 12 13s-2.593-.725-3.178-1.57C8.201 10.534 8 9.488 8 9"
      clipRule="evenodd"
    />
  </svg>
);
