import { useLocale } from '@zep/hooks';
import { useTranslation } from 'next-i18next';

export const usePlanInquiryLink = (linkType?: 'open' | 'replace') => {
  const locale = useLocale();
  const { t } = useTranslation();

  return () => {
    if (linkType === 'replace') {
      window.location.replace(
        locale === 'ko'
          ? `/embed/sales?type=inquiry`
          : t('home.pricing.contactLink'),
      );
      return;
    }

    window.open(
      locale === 'ko'
        ? `/embed/sales?type=inquiry`
        : t('home.pricing.contactLink'),
    );
    return;
  };
};
