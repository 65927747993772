import type { SVGProps } from 'react';
export const OptionHorizontal = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill={props.fill || `currentColor`}
      d="M12 14a2 2 0 1 0 0-4 2 2 0 0 0 0 4M6 14a2 2 0 1 0 0-4 2 2 0 0 0 0 4M18 14a2 2 0 1 0 0-4 2 2 0 0 0 0 4"
    />
  </svg>
);
