import type { SVGProps } from 'react';
export const PenFill = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill={props.fill || `currentColor`}
      d="M17.707 2.293a1 1 0 0 0-1.414 0L14 4.586 19.414 10l2.293-2.293a1 1 0 0 0 0-1.414zM18 11.414 12.586 6l-9.293 9.293A1 1 0 0 0 3 16v4a1 1 0 0 0 1 1h4a1 1 0 0 0 .707-.293z"
    />
  </svg>
);
