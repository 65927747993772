import type { SVGProps } from 'react';
export const CalendarFill = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill={props.fill || `currentColor`}
      d="M9 2a1 1 0 0 1 1 1v1H8V3a1 1 0 0 1 1-1"
    />
    <path
      fill={props.fill || `currentColor`}
      fillRule="evenodd"
      d="M16 4h3a2 2 0 0 1 2 2v13a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h3v3a1 1 0 0 0 2 0V4h4v3a1 1 0 1 0 2 0zm3 7H5v8h14z"
      clipRule="evenodd"
    />
    <path fill={props.fill || `currentColor`} d="M16 4V3a1 1 0 1 0-2 0v1z" />
  </svg>
);
