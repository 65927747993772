import type { SVGProps } from 'react';
export const CurrencyDollarCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill={props.fill || `currentColor`}
      d="M11.693 17.323q-1.717-.093-2.691-.99Q8.027 15.437 8 13.78h2.235q.034.653.416 1.04.388.38 1.042.46v-2.36l-.695-.173q-2.74-.675-2.74-2.87 0-.89.43-1.585.436-.703 1.213-1.12.784-.423 1.792-.494V5.5h.79v1.177q1.016.064 1.78.487.762.416 1.178 1.12.415.702.423 1.607h-2.235q-.042-.48-.334-.782-.286-.309-.811-.395v2.239l.504.129q1.458.336 2.228 1.112.777.767.784 1.987 0 .925-.422 1.614-.417.682-1.207 1.076-.783.395-1.887.452V18.5h-.79zm-1.035-7.59q0 .366.265.61.267.244.77.409V8.714q-.51.08-.777.352a.92.92 0 0 0-.258.667m1.826 5.538q.538-.085.838-.373a.99.99 0 0 0 .32-.717.81.81 0 0 0-.286-.624q-.28-.252-.872-.43z"
    />
    <path
      fill={props.fill || `currentColor`}
      d="M12 4a8 8 0 1 0 0 16 8 8 0 0 0 0-16M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12"
    />
  </svg>
);
