import type { SVGProps } from 'react';
export const StarHalf = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill={props.fill || `currentColor`}
      fillRule="evenodd"
      d="M12.895 3.053a1 1 0 0 0-1.79 0L8.528 8.21l-5.67.824a1 1 0 0 0-.555 1.706l4.127 4.024-.928 5.674a1 1 0 0 0 1.455 1.044L12 18.807l5.044 2.676a1 1 0 0 0 1.455-1.044l-.928-5.674 4.127-4.024a1 1 0 0 0-.554-1.706l-5.67-.824zM12 5.736l1.918 3.836a1 1 0 0 0 .75.543l4.184.608-3.05 2.973a1 1 0 0 0-.289.878l.687 4.198-3.731-1.98a1 1 0 0 0-.469-.117z"
      clipRule="evenodd"
    />
  </svg>
);
