import type { SVGProps } from 'react';
export const Contract = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill={props.fill || `currentColor`}
      d="M19 10a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1V6a1 1 0 1 1 2 0v1.586l4.293-4.293a1 1 0 1 1 1.414 1.414L16.414 9H18a1 1 0 0 1 1 1M9 18v-1.586l-4.293 4.293a1 1 0 0 1-1.414-1.414L7.586 15H6a1 1 0 1 1 0-2h4a1 1 0 0 1 1 1v4a1 1 0 1 1-2 0"
    />
  </svg>
);
