import type { SVGProps } from 'react';
export const MailFill = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill={props.fill || `currentColor`}
      d="M2.027 5.673A2 2 0 0 0 2 6v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V6q0-.175-.029-.34l-9.312 8.093a1 1 0 0 1-1.318 0z"
    />
    <path
      fill={props.fill || `currentColor`}
      d="M3.352 4.107 12 11.671l8.648-7.564A2 2 0 0 0 20 4H4q-.342.002-.648.107"
    />
  </svg>
);
