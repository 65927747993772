import type { SVGProps } from 'react';
export const RotateClockwise = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill={props.fill || `currentColor`}
      d="M16.424 4.41a1 1 0 0 0-.217-.324L14 2a1 1 0 1 0-1.414 1.414l.5.379H8.5c-2.761 0-4.5 2.238-4.5 5v2a1 1 0 1 0 2 0v-2c0-1.657.843-3 2.5-3h4.586l-.5.293A1 1 0 0 0 14 7.5l2.206-2 .008-.007a1 1 0 0 0 .286-.7M16.424 4.41a1 1 0 0 1 .076.38z"
    />
    <path
      fill={props.fill || `currentColor`}
      fillRule="evenodd"
      d="M19.5 9a2 2 0 0 1 2 2v9a2 2 0 0 1-2 2h-9a2 2 0 0 1-2-2v-9a2 2 0 0 1 2-2zm-9 2h9v9h-9z"
      clipRule="evenodd"
    />
  </svg>
);
