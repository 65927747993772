import type { SVGProps } from 'react';
export const TrophyFill = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill={props.fill || `currentColor`}
      fillRule="evenodd"
      d="M7 3a1 1 0 0 0-1 1v1H3a1 1 0 0 0-1 1c0 1.127.275 2.819 1.143 4.265.833 1.389 2.227 2.554 4.347 2.716a5.4 5.4 0 0 0 1.998 1.52c-.064 1.334-.386 2.189-.78 2.702C8.292 17.742 7.725 18 7 18a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1c-.726 0-1.293-.258-1.707-.797-.395-.513-.717-1.368-.781-2.702a5.4 5.4 0 0 0 1.998-1.52c2.12-.162 3.514-1.327 4.348-2.716C21.725 8.819 22 7.127 22 6a1 1 0 0 0-1-1h-3V4a1 1 0 0 0-1-1zm11 4v2c0 .418-.073.985-.253 1.605.61-.32 1.058-.807 1.395-1.37.42-.7.661-1.512.775-2.235zM4.083 7H6v2c0 .418.073.985.253 1.605-.61-.32-1.058-.807-1.396-1.37-.42-.7-.66-1.512-.774-2.235"
      clipRule="evenodd"
    />
  </svg>
);
