import type { SVGProps } from 'react';
export const EyeSlash = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill={props.fill || `currentColor`}
      d="M4.826 7.876c-1.058.98-1.986 2.208-2.72 3.677a1 1 0 0 0 0 .894C4.264 16.764 8.096 19 12 19c1.145 0 2.284-.192 3.377-.574l-1.618-1.617A8.2 8.2 0 0 1 12 17c-2.969 0-6.002-1.62-7.87-5a10.5 10.5 0 0 1 2.112-2.709zM11.05 7.055 9.346 5.351C10.214 5.118 11.106 5 12 5c3.903 0 7.736 2.236 9.894 6.553a1 1 0 0 1 0 .894 13 13 0 0 1-2.243 3.209l-1.414-1.414A10.8 10.8 0 0 0 19.87 12C18.002 8.62 14.97 7 12 7q-.474 0-.95.055"
    />
    <path
      fill={props.fill || `currentColor`}
      d="M8.092 11.142Q8.001 11.557 8 12a4 4 0 0 0 4.858 3.908zM16 12.005 11.995 8H12a4 4 0 0 1 4 4zM2.707 3.707a1 1 0 1 1 1.414-1.414l16.97 16.97a1 1 0 0 1-1.413 1.415z"
    />
  </svg>
);
