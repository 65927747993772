import type { SVGProps } from 'react';
export const MessageDotsSlashFill = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill={props.fill || `currentColor`}
      d="M2.162 5.211A2 2 0 0 0 2 6v11a2 2 0 0 0 2 2h4.586l2.707 2.707a1 1 0 0 0 1.414 0L15.414 19h.537l-6.69-6.689A1.499 1.499 0 0 1 6.5 11.5c0-.53.274-.995.689-1.262zM21.822 17.827c.114-.252.178-.532.178-.827V6a2 2 0 0 0-2-2H7.995l6.722 6.722a1.5 1.5 0 1 1 2.06 2.06zM2.707 3.707a1 1 0 1 1 1.414-1.414l16.97 16.97a1 1 0 0 1-1.413 1.415z"
    />
  </svg>
);
