import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { getUTMParams, saveUTMParams } from '@zep/utils/analytics';
import { isUndefined, omitBy } from 'lodash-es';
import { useRouter } from 'next/router';

import { zepAnalytics } from '../utils/analytics';

export const useInitAnalytics = () => {
  const { isReady } = useRouter();

  useEffect(() => {
    if (isReady) {
      let utmParams = getUTMParams();

      if (Object.values(utmParams).some(value => value)) {
        saveUTMParams(utmParams);
      }

      /** 첫 진입시에만 유저 데이터로 저장합니다.(해당 값이 변경/삭제되면 전체 유저 utm 데이터가 오염됩니다) undefined 일 경우 데이터를 넣지않습니다. */
      zepAnalytics.setDefaultUserProperties({
        ...omitBy(utmParams, isUndefined),
      });
      zepAnalytics.track('quiz_init');
    }
  }, [isReady]);

  useEffect(() => {
    ReactGA.initialize([
      {
        trackingId: `${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_TRACKING_ID}`,
      },
    ]);

    ReactGA.gtag('config', process.env.NEXT_PUBLIC_GOOGLE_ADS_TRACKING_ID);
    ReactGA.event('conversion', {
      send_to: 'AW-11409990767/ZwXSCJfk9qEZEO_I2sAq',
    });
  }, []);
};
