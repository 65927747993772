import type { SVGProps } from 'react';
export const ArrowTurnDownright = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill={props.fill || `currentColor`}
      d="M18.424 14.617a1 1 0 0 1-.217 1.09l-3 3a1 1 0 0 1-1.414-1.414L15.086 16H10a5 5 0 0 1-5-5V5.5a1 1 0 0 1 2 0V11a3 3 0 0 0 3 3h5.086l-1.293-1.293a1 1 0 0 1 1.414-1.414l3 3M18.424 14.617a1 1 0 0 0-.215-.322z"
    />
  </svg>
);
