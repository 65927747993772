import type { SVGProps } from 'react';
export const ArrowsLeftrightSquareFill = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill={props.fill || `currentColor`}
      fillRule="evenodd"
      d="M3 5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2zm6.53 1.22a.75.75 0 0 0-1.06 0L6.22 8.47a.75.75 0 0 0 0 1.06l2.25 2.25a.75.75 0 0 0 1.06-1.06l-.97-.97h7.94a.75.75 0 0 0 0-1.5H8.56l.97-.97a.75.75 0 0 0 0-1.06m4.94 6a.75.75 0 0 1 1.06 0l2.25 2.25a.75.75 0 0 1 0 1.06l-2.25 2.25a.75.75 0 1 1-1.06-1.06l.97-.97H7.5a.75.75 0 0 1 0-1.5h7.94l-.97-.97a.75.75 0 0 1 0-1.06"
      clipRule="evenodd"
    />
  </svg>
);
