import type { SVGProps } from 'react';
export const RotateCounterclockwise = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill={props.fill || `currentColor`}
      d="M9.076 4.41a1 1 0 0 1 .217-.324L11.5 2a1 1 0 1 1 1.414 1.414l-.5.379H17c2.761 0 4.5 2.238 4.5 5v2a1 1 0 1 1-2 0v-2c0-1.657-.843-3-2.5-3h-4.586l.5.293A1 1 0 0 1 11.5 7.5l-2.206-2-.008-.007a1 1 0 0 1-.286-.7M9.076 4.41A1 1 0 0 0 9 4.79z"
    />
    <path
      fill={props.fill || `currentColor`}
      fillRule="evenodd"
      d="M6 9a2 2 0 0 0-2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2-2v-9a2 2 0 0 0-2-2zm9 2H6v9h9z"
      clipRule="evenodd"
    />
  </svg>
);
