import type { SVGProps } from 'react';
export const CurrencyDollarCircleFill = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill={props.fill || `currentColor`}
      d="M10.923 10.343a.8.8 0 0 1-.265-.61q0-.402.258-.667.267-.274.777-.352v2.038q-.503-.165-.77-.41M13.322 14.899q-.3.286-.838.373v-2.146q.593.18.872.43.28.246.286.625a.99.99 0 0 1-.32.718"
    />
    <path
      fill={props.fill || `currentColor`}
      fillRule="evenodd"
      d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2M9.002 16.333q.975.897 2.691.99V18.5h.79v-1.177q1.105-.057 1.888-.452.791-.394 1.207-1.076.422-.688.422-1.614-.007-1.22-.784-1.987-.77-.776-2.228-1.112l-.504-.13V8.715q.525.087.81.395.293.3.335.782h2.235q-.008-.905-.423-1.607a3 3 0 0 0-1.179-1.12q-.762-.423-1.778-.487V5.5h-.79v1.177q-1.009.07-1.793.495-.777.416-1.213 1.119-.43.696-.43 1.585 0 2.196 2.74 2.87l.695.172v2.36q-.654-.078-1.042-.459-.382-.387-.416-1.04H8q.027 1.658 1.002 2.554"
      clipRule="evenodd"
    />
  </svg>
);
