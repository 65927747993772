import type { SVGProps } from 'react';
export const CloudSlash = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill={props.fill || `currentColor`}
      d="M5.246 8.296a6 6 0 0 0-.232 2.115A5 5 0 0 0 7 20.001h9q.451 0 .886-.065l-1.935-1.935H7a3 3 0 0 1-.66-5.927 1 1 0 0 0 .757-1.195A4 4 0 0 1 7 10.051zM10.097 6.103l-1.57-1.57a6.002 6.002 0 0 1 8.144 3.505 6 6 0 0 1 4.45 9.09l-1.476-1.477A4 4 0 0 0 15.92 10a1 1 0 0 1-1-.8 4 4 0 0 0-4.823-3.098M4.593 5.594A1 1 0 0 1 6.007 4.18l14.67 14.67a1 1 0 1 1-1.414 1.414z"
    />
  </svg>
);
