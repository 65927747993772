import type { SVGProps } from 'react';
export const Minimizescreen = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill={props.fill || `currentColor`}
      fillRule="evenodd"
      d="M10 9a1 1 0 0 1-1 1H4a1 1 0 1 1 0-2h4V4a1 1 0 1 1 2 0zM15 10a1 1 0 0 1-1-1V4a1 1 0 1 1 2 0v4h4a1 1 0 1 1 0 2zM14 15a1 1 0 0 1 1-1h5a1 1 0 1 1 0 2h-4v4a1 1 0 1 1-2 0zM9 14a1 1 0 0 1 1 1v5a1 1 0 1 1-2 0v-4H4a1 1 0 1 1 0-2z"
      clipRule="evenodd"
    />
  </svg>
);
