import type { SVGProps } from 'react';
export const ArrowTurnRightdown = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill={props.fill || `currentColor`}
      d="M14.617 18.424a1 1 0 0 0 1.09-.217l3-3a1 1 0 0 0-1.414-1.414L16 15.086V10a5 5 0 0 0-5-5H5.5a1 1 0 1 0 0 2H11a3 3 0 0 1 3 3v5.086l-1.293-1.293a1 1 0 0 0-1.414 1.414l3 3M14.617 18.424a1 1 0 0 1-.322-.215z"
    />
  </svg>
);
