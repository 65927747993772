import type { SVGProps } from 'react';
export const SlashCircleFill = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill={props.fill || `currentColor`}
      d="M5.902 4.074A9.96 9.96 0 0 1 12 2c5.523 0 10 4.477 10 10 0 2.295-.773 4.41-2.074 6.098zM4.447 5.447A9.96 9.96 0 0 0 2 12c0 5.523 4.477 10 10 10a9.96 9.96 0 0 0 6.554-2.447z"
    />
  </svg>
);
